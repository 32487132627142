import Rect from './rect.js'
import Vec from './vec.js'

export default class Ball extends Rect
{
    constructor(ctx, beadSize)
    {
        super(beadSize, beadSize);
        this._context = ctx;
        this.vel = new Vec;
        this.beads = new Array();
        this.needPosition = true;
    }
    update(dt)
    {
        //this.pos.x += this.vel.x * dt;
        //this.pos.y += this.vel.y * dt;

        var pixels = 15; // + (Math.random() * 65);
        var snap = 4;

        if (Math.abs(this.TargetX - this.pos.x) < snap && Math.abs(this.TargetY - this.pos.y) < snap)
            return;

        if (this.pos.x < this.TargetX) {
            this.pos.x += (this.TargetX - this.pos.x) / pixels;
        }
        if (this.pos.x > this.TargetX) {
            this.pos.x -= (this.pos.x - this.TargetX) / pixels;
        }
        if (this.pos.y < this.TargetY) {
            this.pos.y += (this.TargetY - this.pos.y) / pixels;
        }
        if (this.pos.y > this.TargetY) {
            this.pos.y -= (this.pos.y - this.TargetY) / pixels;
        }

        // if (this.pos.x < this.TargetX) {
        //     this.pos.x += pixels;
        // }
        // if (this.pos.x > this.TargetX) {
        //     this.pos.x -= pixels;
        // }
        // if (this.pos.y < this.TargetY) {
        //     this.pos.y += pixels;
        // }
        // if (this.pos.y > this.TargetY) {
        //     this.pos.y -= pixels;
        // }




        // if (Math.abs(this.TargetX - this.pos.x) < snap)
        //     this.pos.x = this.TargetX;

        // if (Math.abs(this.TargetY - this.pos.y) < snap)
        //     this.pos.y = this.TargetY;




    }

    draw() {
        //this._context.fillRect(this.left, this.top, this.size.x, this.size.y);
        //debugger;

        
        // this._context.strokeStyle = this.color;
        // this._context.beginPath();
        // this._context.arc(this.pos.x, this.pos.y, this.size.x, 0, Math.PI * 2, true);
        // this._context.closePath();
        // this._context.stroke();

        // // var orangeGradient = this._context.createLinearGradient(this.left - this.size.x, 0, this.left + 2, 0);
        // // orangeGradient.addColorStop(0, '#fff');
        // // orangeGradient.addColorStop(1, this.color);
        // // this._context.fillStyle = orangeGradient;
        
        //this._context.fillStyle = this.color;
        // //this._context.fillRect(this.left, this.top, this.size.x * 2, this.size.y * 2);
        //this._context.fill();
        // //this._context.stroke();
        

        this._context.drawImage(this.image, this.pos.x - this.size.x, this.pos.y - this.size.y, this.size.x * 2, this.size.y * 2);

        // Draw ID, etc
        this._context.fillStyle = "#FFFF99";
        //this._context.fillStyle = "#666";
        this._context.font = "12px Segoe UI";
        this._context.textBaseline = "middle";
        this._context.textAlign = "center";
        this._context.fillText(this.tag, this.pos.x - (this.size.x * 0), this.pos.y - (this.size.y * 0));

    }



}
