import Bao from './bao.js'
import './style.css'

import Bead0 from './images/bead0.gif'
import Bead1 from './images/bead1.gif'
import JGlzr from './images/jGlzr.png'

var bead0 = document.getElementById("bead0");
var bead1 = document.getElementById("bead1");
var jGlzr = document.getElementById("jGlzr");

bead0.src = Bead0;
bead1.src = Bead1;
jGlzr.src = JGlzr;

const canvas = document.querySelector('#bao');
const container = document.querySelector('#container');

var width = window.innerWidth;
var height = window.innerHeight;

canvas.width = width;
canvas.height = height;

canvas.style.left = "0px";
canvas.style.top = "0px";
//canvas.style.absolute = "0px";

const bao = new Bao(canvas, container);

canvas.addEventListener('click', (event) => bao.click(event));

window.onresize = function() {
    width = window.innerWidth;
    height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;
    bao.resize();
}


// canvas.addEventListener('mousemove', event => {
//     const scale = event.offsetY / event.target.getBoundingClientRect().height;
//     bao.players[0].pos.y = canvas.height * scale;
// });

bao.start();