import Player from './player.js'


export default class State
{
    constructor(gameMode, holes0, holes1)
    {
        this.gameMode = gameMode;

        this.players = [
            new Player,
            new Player,
        ];

        this.players[0].id = 0;
        this.players[1].id = 1;

        this.holes = new Array();
        this.holes[0] = new Array(this.gameMode.columnsPerSide * 2);
        this.holes[1] = new Array(this.gameMode.columnsPerSide * 2);

        this.players[0].holes = this.holes[0];
        this.players[1].holes = this.holes[1];

        //this.players[0].opponent = this.players[1];
        //this.players[1].opponent = this.players[0];

        this.players[0].score = 0;
        this.players[1].score = 0;

        // Side 0
        for (var i = 0; i < this.gameMode.columnsPerSide * 2; i++) {
            this.holes[0][i] = new Object();
            this.holes[0][i].id = i;
            this.holes[0][i].numBeads = holes0[i].numBeads;
            this.players[0].score += holes0[i].numBeads;
        }

        // Side 1
        for (var i = 0; i < this.gameMode.columnsPerSide * 2; i++) {
            this.holes[1][i] = new Object();
            this.holes[1][i].id = i;
            this.holes[1][i].numBeads = holes1[i].numBeads;
            this.players[1].score += holes1[i].numBeads;
        }

        // Set up Hole links
        for (var i = 0; i < this.gameMode.columnsPerSide * 2; i++) {
            if (i > 0)
            {
                this.holes[0][i - 1].nextId = i;
                this.holes[1][i - 1].nextId = i;
            }
            else
            {
                this.holes[0][this.gameMode.columnsPerSide * 2 - 1].nextId = i;
                this.holes[1][this.gameMode.columnsPerSide * 2 - 1].nextId = i;
            }

            if (i > (this.gameMode.columnsPerSide - 1)) {
                this.holes[0][i].oppositeHoleNumber = (this.gameMode.columnsPerSide * 2) - (i - this.gameMode.columnsPerSide) - 1;
            }

        }
    }

    calcScore()
    {
        this.players[0].score = 0;
        this.players[1].score = 0;
        this.holes[0].forEach(hole => this.players[0].score += hole.numBeads);
        this.holes[1].forEach(hole => this.players[1].score += hole.numBeads);
    }

}