import State from './state.js'


export default class AI
{

    constructor(state)
    {
        this.initialState = state;
    }

    suggestMove(playerId)
    {
        var suggestedMove = -1;
        var maxGain = -100000;
        
        if (playerId == 0)
        {
            maxGain = 0;
            for (var t = 0; t < this.initialState.gameMode.columnsPerSide * 2; t++)
            {
                // Copy the state and call findBestMove
                let playState = this.copyState (this.initialState);

                console.log("playState", playState);

                var holes;
                var player;
                var opponent;

                if (playerId == 0)
                {
                    player = playState.players[0];
                    opponent = playState.players[1];
                    holes = playState.holes[0];
                }
                else
                {
                    player = playState.players[1];
                    opponent = playState.players[0];
                    holes = playState.holes[1];
                }

                if (holes[t].numBeads > 1) {

                    aiPlayPos = t;

                    var startScore = player.score;

                    this.playHole(playState, player, opponent, aiPlayPos);

                    playState.calcScore();

                    var gain = player.score - startScore;
                    console.log("Player", playerId,"t", t, "aiPlayPos", aiPlayPos, "startScore", startScore, "newScore", player.score, "Gain", gain, "maxGain", maxGain);

                    if (gain > maxGain)
                    {
                        suggestedMove = aiPlayPos;
                        maxGain = gain;
                    }
                }
            }
        }
        else
        {
            for (var t = 0; t < 2; t++)
            {
                // Copy the state and call findBestMove
                let playState = this.copyState (this.initialState);

                var aiPlayPos = this.findRandomMove(playerId, playState);

                if (aiPlayPos >= 0)
                {
                    var player;
                    var opponent;
                    if (playerId == 0)
                    {
                        player = playState.players[0];
                        opponent = playState.players[1];
                    }
                    else
                    {
                        player = playState.players[1];
                        opponent = playState.players[0];
                    }

                    var startScore = player.score;

                    this.playHole(playState, player, opponent, aiPlayPos);

                    playState.calcScore();

                    var gain = player.score - startScore;
                    console.log("t", t, "aiPlayPos", aiPlayPos, "startScore", startScore, "newScore", player.score, "Gain", gain, "maxGain", maxGain);

                    if (gain > maxGain)
                    {
                        suggestedMove = aiPlayPos;
                        maxGain = gain;
                    }
                }
            }
        }

        return suggestedMove;
    }

    copyState (state)
    {
        let playState = JSON.parse(JSON.stringify(state));
        Object.setPrototypeOf(playState, State.prototype);
        playState.players[0].holes = playState.holes[0];
        playState.players[1].holes = playState.holes[1];
        return playState;
    }

    findRandomMove(playerId, playState)
    {
        var aiPlayPos = -1;

        var theHoles;
        var player;
        if (playerId == 0)
        {
            //console.log("Choosing Player Position");
            theHoles = playState.holes[0];
            player = playState.players[0];
        }
        else
        {
            //console.log("Choosing AI Position");
            theHoles = playState.holes[1];
            player = playState.players[1];
        }

        var tryAgain = true;
        var tryCount = 200;
        while (tryAgain && tryCount > 0) {
            aiPlayPos = Math.floor(Math.random() * this.initialState.gameMode.columnsPerSide * 2);
            if (theHoles[aiPlayPos].numBeads > 1) {
                tryAgain = false;
            }

            if (tryAgain)
            {
                tryCount--;
                if (tryCount == 0)
                {
                    aiPlayPos = -1;
                }
            }
        }

        return aiPlayPos;
    }
    checkHole(playState, player, opponent, selectedHoleNumber) {


        
    }

    playHole(playState, player, opponent, selectedHoleNumber) {

        var selectedHole = player.holes[selectedHoleNumber];

        var lastHoleNumber = -1;

        while (selectedHole.numBeads > 1) {
            // Next Hole
            var nextHoleNumber = selectedHoleNumber + 1;
            if (nextHoleNumber >= this.initialState.gameMode.columnsPerSide * 2)
                nextHoleNumber = 0;

            var lastHoleBeadCount = 0;
            for (var i = 0; i < selectedHole.numBeads; i++) {

                lastHoleBeadCount = 0;
                //var b = selectedHole.beads[i];

                // Queue the move
                player.holes[nextHoleNumber].numBeads++;
                lastHoleBeadCount = player.holes[nextHoleNumber].numBeads;

                lastHoleNumber = nextHoleNumber;
                nextHoleNumber++;
                if (nextHoleNumber >= this.initialState.gameMode.columnsPerSide * 2)
                    nextHoleNumber = 0;

            }
            selectedHole.numBeads = 0;

            selectedHoleNumber = lastHoleNumber;
            selectedHole = player.holes[lastHoleNumber];
           
            // Check opposite Hole

            var oppositeHoleNumber = -1;
            if (selectedHoleNumber > (this.initialState.gameMode.columnsPerSide - 1) && lastHoleBeadCount > 1) {
                oppositeHoleNumber = (this.initialState.gameMode.columnsPerSide * 2) - (selectedHoleNumber - this.initialState.gameMode.columnsPerSide) - 1;

                var oppositeHole = opponent.holes[oppositeHoleNumber];

                if (oppositeHole.numBeads > 0) {

                    for (var i = 0; i < oppositeHole.numBeads; i++)
                    {
                        player.holes[nextHoleNumber].numBeads++;

                        lastHoleNumber = nextHoleNumber;
                        nextHoleNumber++;
                        if (nextHoleNumber >= this.initialState.gameMode.columnsPerSide * 2)
                            nextHoleNumber = 0;
                    }

                    oppositeHole.numBeads = 0;
                    selectedHoleNumber = lastHoleNumber;
                    selectedHole = player.holes[lastHoleNumber];
                }
            }
        }
        
    }





    // Javascript program to find maximum score that
    // maximizing player can get.
    
    // Returns the optimal value a maximizer can obtain.
    // depth is current depth in game tree.
    // nodeIndex is index of current node in scores[].
    // isMax is true if current move is of maximizer, else false
    // scores[] stores leaves of Game tree.
    // h is maximum height of Game tree

    minimax(depth, nodeIndex, isMax, scores, h) 
    {
        // Terminating condition. i.e leaf node is reached
        if (depth == h)
        {
            return scores[nodeIndex];
        }

        // If current move is maximizer, find the maximum attainable
        // value
        if (isMax)
        {
            return Math.max(minimax(depth+1, nodeIndex*2, false, scores, h),
                minimax(depth+1, nodeIndex*2 + 1, false, scores, h));
        }
        // Else (If current move is Minimizer), find the minimum
        // attainable value
        else
        {
            return Math.min(minimax(depth+1, nodeIndex*2, true, scores, h),
                minimax(depth+1, nodeIndex*2 + 1, true, scores, h));
        }
    }

    // A utility function to find Log n in base 2
    log2(n)
    {
        return (n==1)? 0 : 1 + log2(n/2);
    }


    // Driver Code

    // The number of elements in scores must be
    // a power of 2.
    // let scores = [3, 5, 2, 9, 12, 5, 23, 23];
    // let n = scores.length;
    // let h = log2(n);
    // let res = minimax(0, 0, true, scores, h);
    // document.write( "The optimal value is : "  +res);
}