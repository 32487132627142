import Vec from './vec.js'

export default class Rect
{
    constructor(x = 0, y = 0)
    {
        this.pos = new Vec(0, 0);
        this.size = new Vec(x, y);
    }
    get left()
    {
        return this.pos.x - this.size.x / 2;
    }
    get right()
    {
        return this.pos.x + this.size.x / 2;
    }
    get top()
    {
        return this.pos.y - this.size.y / 2;
    }
    get bottom()
    {
        return this.pos.y + this.size.y / 2;
    }

    hittest(vec)
    {
        if (this.left < vec.x && this.right > vec.x && this.top < vec.y && this.bottom > vec.y) {
            return true;
        }

        return false;
    }


}