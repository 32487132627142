class GameMode
{
    constructor(name = 'Standard')
    {
        this.name = name
        this.hole0Color = "#eee";
        this.hole1Color = "#D3D3D3";
        this.actionHoleColor = "#FFF3C1";
        this.gameOverColor = "#FFDDC1";
        this.tableColor = "#aaa";
        this.hintColor = "#FF6A00";
        this.selectedColor = "#00f";
        this.scoreColor0 = "#C00";
        this.scoreColor1 = "#333";
    }
}


export default class Mode16 extends GameMode
{
    constructor()
    {
        super('Standard Game');
        this.columnsPerSide = 8;
        this.startingBeads = 32; //this.columnsPerSide * 4; // 32
        this.openingHolesCount = 8;
        this.openingHolesBeads = 4;
    }

    update(dt)
    {
    }

    draw() 
    {

    }
}

class Mode32 extends GameMode
{
    constructor()
    {
        super('Double Size');
        this.columnsPerSide = 16;
        this.startingBeads = 40;
        this.openingHolesCount = 16;
        this.openingHolesBeads = 2;
    }

    update(dt)
    {
    }

    draw() 
    {

    }
}