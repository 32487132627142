import Mode16 from './mode.js'
import Player from './player.js'
import Ball from './ball.js'
import Hole from './hole.js'
import Vec from './vec.js'
import Move from './move.js'
import State from './state.js'
import AI from './ai.js'


const beadSize = 14;

export default class Bao
{
    constructor(canvas, container)
    {
        this._canvas = canvas;
        this._context = canvas.getContext('2d');
        
        this.container = container;
        //canvas.width = container.clientWidth;
        //canvas.height = container.clientHeight;

        this.nextMoveWait = 0;
        this.initialSpeed = 250;
        this.playstate = 0;

        this.gameMode = new Mode16()

        //this.ball = new Ball;

        this.queue = new Array();

        this.players = [
            new Player,
            new Player,
        ];

        this.clickPos = new Array();

        this.players[0].id = 0;
        this.players[1].id = 1;

        this.players[0].score = this.gameMode.startingBeads;
        this.players[1].score = this.gameMode.startingBeads;

        this.allBeads = new Array(this.gameMode.startingBeads * 2);

        this.bead0 = document.getElementById("bead0");
        this.bead1 = document.getElementById("bead1");
        this.jGlzr = document.getElementById("jGlzr");

        for (var i = 0; i < this.gameMode.startingBeads * 2; i++) {
            this.allBeads[i] = new Ball(this._context, beadSize);
            this.allBeads[i].color = "#ff6";
            this.allBeads[i].pos.x = Math.random() * this._canvas.width;
            this.allBeads[i].pos.y = Math.random() * this._canvas.height;
            this.allBeads[i].priority = 0;
            this.allBeads[i].moveCount = 1;
        }


        this.holes = new Array();

        this.holes[0] = new Array(this.gameMode.columnsPerSide * 2);
        this.holes[1] = new Array(this.gameMode.columnsPerSide * 2);

        this.players[0].holes = this.holes[0];
        this.players[1].holes = this.holes[1];

        this.players[0].opponent = this.players[1];
        this.players[1].opponent = this.players[0];

        var b = 0;
        var beadsToPlace = this.gameMode.startingBeads;

        for (var i = 0; i < this.gameMode.columnsPerSide * 2; i++) {
            this.holes[0][i] = new Hole(this, this._context, 0, 0, beadSize);
            this.holes[0][i].color = this.gameMode.hole0Color;
            this.holes[0][i].savecolor = this.holes[0][i].color;
            this.holes[0][i].player = "Player1";
            this.holes[0][i].id = i;
            this.holes[0][i].beads = new Array();
            this.holes[0][i].numBeads = 0;

            // if (i > 23) {
            //     this.holes[0][i].numBeads = 0;
            // }
            // else if (i > 15) {
            //     this.holes[0][i].numBeads = 1;
            // }
            // else {
            //     this.holes[0][i].numBeads = 2;
            // }


            //console.log(i, this.holes[0][i].player, this.holes[0][i].id, beadsToPlace);

            if (i < this.gameMode.openingHolesCount) {
                if (beadsToPlace > 0) {
                    this.holes[0][i].numBeads = this.gameMode.openingHolesBeads;
                    beadsToPlace -= this.gameMode.openingHolesBeads;
                    }
            } else {
                if (beadsToPlace > 0) {
                    this.holes[0][i].numBeads = 1;
                    beadsToPlace -= 1;
                }
            } 


            for (var x = 0; x < this.holes[0][i].numBeads; x++) {
                this.allBeads[b].currentHole = this.holes[0][i];
                this.allBeads[b].id = "P" + b;
                this.allBeads[b].player = "Player1";
                this.allBeads[b].color = this.gameMode.scoreColor0;
                this.allBeads[b].numberInHole = x;
                this.allBeads[b].image = this.bead0;
                this.holes[0][i].beads.push(this.allBeads[b]);
                b++;
            }

        }

        // Side 2
        //b = 0;
        beadsToPlace = this.gameMode.startingBeads;
        for (var i = 0; i < this.gameMode.columnsPerSide * 2; i++) {
            this.holes[1][i] = new Hole(this, this._context, 0, 0, beadSize);
            this.holes[1][i].color = this.gameMode.hole1Color;
            this.holes[1][i].savecolor = this.holes[1][i].color;
            this.holes[1][i].player = "AI";
            this.holes[1][i].id = i;
            this.holes[1][i].beads = new Array();
            this.holes[1][i].numBeads = 0;


            // if (i > 23) {
            //     this.holes[1][i].numBeads = 0;
            // }
            // else if (i > 15) {
            //     this.holes[1][i].numBeads = 1;
            // }
            // else {
            //     this.holes[1][i].numBeads = 2;
            // }

            if (i < this.gameMode.openingHolesCount) {
                if (beadsToPlace > 0) {
                    this.holes[1][i].numBeads = this.gameMode.openingHolesBeads;
                    beadsToPlace -= this.gameMode.openingHolesBeads;
                }
            } else {
                if (beadsToPlace > 0) {
                    this.holes[1][i].numBeads = 1;
                    beadsToPlace -= 1;
                }
            }

            for (var x = 0; x < this.holes[1][i].numBeads; x++) {
                this.allBeads[b].currentHole = this.holes[1][i];
                this.allBeads[b].id = "C" + b;
                this.allBeads[b].player = "AI";
                this.allBeads[b].color = this.gameMode.scoreColor1;
                this.allBeads[b].numberInHole = x;
                this.allBeads[b].image = this.bead1;
                this.holes[1][i].beads.push(this.allBeads[b]);
                b++;
            }

        }

        // Set up Hole links
        for (var i = 0; i < this.gameMode.columnsPerSide; i++) {
            if (i > 0)
            {
                this.holes[0][i - 1].next = this.holes[0][i];
                this.holes[1][i - 1].next = this.holes[1][i];
            }
            else
            {
                this.holes[0][this.gameMode.columnsPerSide - 1].next = this.holes[0][i];
                this.holes[1][this.gameMode.columnsPerSide - 1].next = this.holes[1][i];
            }
        }

        this.allBeads.forEach(bead => 
            bead.speed = 15 + (Math.random() * 15)
            );


        this.resize();


        // this.players[0].pos.x = 200;
        // this.players[1].pos.x = this._canvas.width - 200;

        // this.players.forEach(p => p.pos.y = this._canvas.height / 2);

        let lastTime = null;
        let accumulator = 0;
        const step = 1/120;
        this._frameCallback = (millis) => {
            if (lastTime !== null) {
                const diff = (millis - lastTime) / 1000;
                accumulator += diff;
                while (accumulator > step) {
                    this.update(step);
                    accumulator -= step;
                }
                this.draw();
            }
            lastTime = millis;
            requestAnimationFrame(this._frameCallback);
        };

        this.CHAR_PIXEL = 8;
        this.CHARS0 = [
            '111101101101111',
            '010010010010010',
            '111001111100111',
            '111001111001111',
            '101101111001001',
            '111100111001111',
            '111100111101111',
            '111001001001001',
            '111101111101111',
            '111101111001111',
        ].map(str => {
            const canvas = document.createElement('canvas');
            const s = this.CHAR_PIXEL;
            canvas.height = s * 5;
            canvas.width = s * 3;
            const context = canvas.getContext('2d');
            context.fillStyle = this.gameMode.scoreColor0; // '#69c';
            str.split('').forEach((fill, i) => {
                if (fill === '1') {
                    context.fillRect((i % 3) * s, (i / 3 | 0) * s, s, s);
                }
            });
            return canvas;
        });

        this.CHARS1 = [
            '111101101101111',
            '010010010010010',
            '111001111100111',
            '111001111001111',
            '101101111001001',
            '111100111001111',
            '111100111101111',
            '111001001001001',
            '111101111101111',
            '111101111001111',
        ].map(str => {
            const canvas = document.createElement('canvas');
            const s = this.CHAR_PIXEL;
            canvas.height = s * 5;
            canvas.width = s * 3;
            const context = canvas.getContext('2d');
            context.fillStyle = this.gameMode.scoreColor1; // '#69c';
            str.split('').forEach((fill, i) => {
                if (fill === '1') {
                    context.fillRect((i % 3) * s, (i / 3 | 0) * s, s, s);
                }
            });
            return canvas;
        });


        this.reset();
    }

    resize()
    {
        var xborder = 20;
        var yborder = 20;
        var GridWidth = this._canvas.width - xborder * 2;
        var GridHeight = this._canvas.height - yborder * 2 - 4;

        var x_grid_inc = GridWidth / this.gameMode.columnsPerSide;
        var y_grid_inc = GridHeight / 4;

        var GridX = (x_grid_inc / 2) + xborder; //((GridWidth / 2) + (x_grid_inc / 2)) - ((this.gameMode.columnsPerSide / 2) * x_grid_inc) + (x_grid_inc / 4); //55;
        var GridY = (y_grid_inc / 2) + yborder; // (GridHeight / 2);

        for (var i = 0; i < this.gameMode.columnsPerSide; i++)
        {
            this.holes[1][i].initialize                               (GridX + ((this.gameMode.columnsPerSide - 1 - i) * x_grid_inc)   , GridY + (0 * y_grid_inc), x_grid_inc, y_grid_inc); // AI top/outer
            this.holes[1][i + this.gameMode.columnsPerSide].initialize(GridX + (i * x_grid_inc)                                        , GridY + (1 * y_grid_inc), x_grid_inc, y_grid_inc); // AI bottom/inner
            this.holes[0][i + this.gameMode.columnsPerSide].initialize(GridX + ((this.gameMode.columnsPerSide - 1 - i) * x_grid_inc)   , GridY + (2 * y_grid_inc), x_grid_inc, y_grid_inc); // Player bottom/outer
            this.holes[0][i].initialize                               (GridX + (i * x_grid_inc)                                        , GridY + (3 * y_grid_inc), x_grid_inc, y_grid_inc); // Player top/inner
        }

        this.allBeads.forEach(bead => bead.needPosition = true);
    }    

    clear()
    {
        this._context.fillStyle = this.gameMode.tableColor;
        this._context.fillRect(0, 0, this._canvas.width, this._canvas.height);

        this._context.drawImage(this.jGlzr, 0, 0, this._canvas.width, this._canvas.height);


    }

    collide(player, ball)
    {
        if (player.left < ball.right && player.right > ball.left && player.top < ball.bottom && player.bottom > ball.top) {
            ball.vel.x = -ball.vel.x * 1.05;
            if (ball.vel.x > 500) {
                ball.vel.x = 500;
            }
            console.log((ball.top - player.top) / player.size.x);
            const len = ball.vel.len;
            // ball.vel.y += player.vel.y * .2;
            ball.vel.y += (ball.top - player.top) / player.size.x * .2;
            ball.vel.len = len;
        }
    }

    draw()
    {
        this.clear();

        // var rect = this.container.getBoundingClientRect();
        // this._context.fillStyle = '#ff0';
        // this._context.fillRect(rect.left, rect.top, rect.width, rect.height);

        this.holes[0].forEach(hole => hole.draw());
        this.holes[1].forEach(hole => hole.draw());

        this.allBeads.forEach(bead => bead.draw());

        //this.drawRect(this.ball);
        this.players.forEach(player => player.draw());

        this.drawScore();

        this._context.save();
        
        while(this.clickPos.length > 10) {
            this.clickPos.shift();
        }

        this.clickPos.forEach((cPos, index) => {
            if (cPos.x > 0)
            {
                this._context.beginPath();
                this._context.arc(cPos.x, cPos.y, 5, 0, Math.PI * 2, true);
                this._context.closePath();
            
                this._context.fillStyle = "#999";
                this._context.globalAlpha = 1.0 - ((this.clickPos.length - index) * 0.1);
                this._context.fill();
            }
        });
        this._context.restore();

    }

    drawRect(rect)
    {
        this._context.fillStyle = '#fff';
        this._context.fillRect(rect.left, rect.top, rect.size.x, rect.size.y);
    }

    drawScore()
    {
        const align = this._canvas.width / 3;
        const cw = this.CHAR_PIXEL * 4;

        var charsImages = [
            this.CHARS0,
            this.CHARS1,
        ];

        this.players.forEach((player, index) => {
            const chars = player.score.toString().split('');
            if (index == 0 && this.playstate == 0) {
                this._context.fillStyle = this.gameMode.scoreColor0;

                const theLeft = align * (index + 1) - 50;


                //this._context.fillRect(theLeft, 20, 10, 10);            

                this._context.beginPath();
                this._context.arc(theLeft, 26, 6, 0, Math.PI * 2, true);
                this._context.closePath();
                this._context.fill();


            }
            const offset = align * (index + 1) - (cw * chars.length / 2) + this.CHAR_PIXEL / 2;
            chars.forEach((char, pos) => {
                this._context.drawImage(charsImages[index][char|0], offset + pos * cw, 20);
            });
        });
    }

    play()
    {
        const b = this.ball;
        if (b.vel.x === 0 && b.vel.y === 0) {
            b.vel.x = 200 * (Math.random() > .5 ? 1 : -1);
            b.vel.y = 200 * (Math.random() * 2 - 1);
            b.vel.len = this.initialSpeed;
        }
    }

    

    reset()
    {
        // const b = this.ball;
        // b.vel.x = 0;
        // b.vel.y = 0;
        // b.pos.x = this._canvas.width / 2;
        // b.pos.y = this._canvas.height / 2;


        for (var i = 0; i < this.gameMode.startingBeads * 2; i++) {
            this.allBeads[i].pos.x = Math.random() * this._canvas.width;
            this.allBeads[i].pos.y = Math.random() * this._canvas.height;
            this.allBeads[i].needPosition = true;
        }

    }

    start()
    {
        requestAnimationFrame(this._frameCallback);
    }

    update(dt)
    {
        //const cvs = this._canvas;
        //const ball = this.ball;

        if (this.queue.length > 0) {
            //console.log(this.nextMoveWait);
            if (this.nextMoveWait <= 0) {
                var move = this.queue.shift();
                if (move.playerID >= 0) {
                    //move.holeTo.color = "#009933";
                    move.holeTo.color = this.gameMode.actionHoleColor;
                    var bead = move.holeFrom.beads.shift();
                    if (bead != null) {
                        bead.currentHole = move.holeTo;
                        bead.needPosition = true;
                        move.holeTo.beads.push(bead);
                    }
                    this.nextMoveWait = 0.05;
                }
                else {
                    this.nextMoveWait = 0.1;
                }
                this.players[1].score = 0;
                this.players[0].score = 0;

                this.holes[0].forEach(hole => this.players[0].score += hole.beads.length);
                this.holes[1].forEach(hole => this.players[1].score += hole.beads.length);

            }
            this.nextMoveWait -= dt;
        } else {
            if (this.playstate == 1) {

                this.nextMoveWait = 1;

                // Let AI Play
                var gameState = new State(this.gameMode, this.holes[0], this.holes[1]);
                var ai = new AI(gameState);
                var suggestedPos = ai.suggestMove(1);
                if (suggestedPos >= 0)
                {
                    console.log("AI Playing hole", suggestedPos);
                    this.holes[1][suggestedPos].selected = true;
                    this.playHole(this.players[1], suggestedPos);
                    this.playstate = 2;
                }
                else
                {
                    this.playstate = 3;
                    this.holes[0].forEach(hole => hole.color = this.gameMode.gameOverColor);
                    this.holes[1].forEach(hole => hole.color = this.gameMode.gameOverColor);
                }


                // var tryAgain = true;
                // var tryCount = 200;
                // while (tryAgain && tryCount > 0) {
                //     var aiPlayPos = Math.floor(Math.random() * this.gameMode.columnsPerSide * 2);
                //     if (this.holes[1][aiPlayPos].beads.length > 1) {
                //         tryAgain = false;
                //         this.playHole(this.players[1], aiPlayPos);
                //         this.playstate = 2;
                //     }
                //     tryCount--;
                //     if (tryCount == 0)
                //     {
                //         this.playstate = 3;
                //         this.holes[0].forEach(hole => hole.color = "#352288");
                //         this.holes[1].forEach(hole => hole.color = "#352288");
                //     }
                // }
            } 
            else if (this.playstate == 2) {
                this.playstate = 0;
                this.holes[0].forEach(hole => hole.color = hole.savecolor);
                this.holes[1].forEach(hole => hole.color = hole.savecolor);

                this.holes[0].forEach(hole => hole.suggested = false);
                this.holes[1].forEach(hole => hole.suggested = false);

                this.holes[0].forEach(hole => hole.selected = false);
                this.holes[1].forEach(hole => hole.selected = false);


                var gameState = new State(this.gameMode, this.holes[0], this.holes[1]);
                var ai = new AI(gameState);
                var suggestedPos = ai.suggestMove(0);
                if (suggestedPos >= 0)
                {
                    this.holes[0][suggestedPos].suggested = true;
                }
            }
        }

        this.holes[0].forEach(hole => hole.update(dt));
        this.holes[1].forEach(hole => hole.update(dt));

        // this.players.forEach(player => {
        //     player.update(dt);
        // });
    }

    getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: evt.clientX - rect.left,
            y: evt.clientY - rect.top
        };
    }



    onClick(event) {

        var element = this.container;
        var offsetX = 0, offsetY = 0
    
        if (element.offsetParent) {
          do {
            offsetX += element.offsetLeft;
            offsetY += element.offsetTop;
          } while ((element = element.offsetParent));
        }
    
        var x = event.pageX - offsetX;
        var y = event.pageY - offsetY;

    }


    click(event) {

        if (this.playstate == 0) {

            this.onClick(event);

            var vec = new Vec(0, 0);
            var mousePos = this.getMousePos(this._canvas, event);
            vec.x = mousePos.x;
            vec.y = mousePos.y;

            this.clickPos.push(new Vec(vec.x, vec.y));

            while(this.clickPos.length > 10)
            {
                this.clickPos.shift();
            }
    

            var goOn = true;
            this.holes[0].forEach(hole => {
                //hole.color = hole.savecolor;
                hole.selected = false;
                if (goOn && hole.hittest(vec)) {
                    goOn = false;
                    //hole.color = "#FF3399";
                    hole.selected = true;
                    this.playstate = 1;
                    this.playHole(this.players[0], hole.id);
                }
            });




            // goOn = true;
            // this.holes[1].forEach(hole => {
            //     if (goOn && hole.hittest(vec)) {
            //         goOn = false;
            //         hole.color = "#003366";
            //         this.playstate = 1;
            //         this.playHole(this.players[1], hole.id);
            //     }
            // });


        } else {
            //hole.color = "#33AA66";
            //this.reset();
            //this.playstate = 0;
        }
    }

    playHole(player, selectedHoleNumber) {

        var selectedHole = player.holes[selectedHoleNumber];
        console.log(player.id, "selectedHoleNumber s", selectedHoleNumber);

        var lastHoleNumber = -1;

        while (selectedHole.numBeads > 1) {
            // Next Hole
            var nextHoleNumber = selectedHoleNumber + 1;
            if (nextHoleNumber >= this.gameMode.columnsPerSide * 2)
                nextHoleNumber = 0;

            var lastHoleBeadCount = 0;
            for (var i = 0; i < selectedHole.numBeads; i++) {

                lastHoleBeadCount = 0;
                //var b = selectedHole.beads[i];

                // Queue the move
                var move = new Move();
                move.playerID = 0;
                //move.beadID = b.beadID;
                move.holeFrom = selectedHole;
                move.holeTo = player.holes[nextHoleNumber];
                this.queue.push(move);

                player.holes[nextHoleNumber].numBeads++;
                lastHoleBeadCount = player.holes[nextHoleNumber].numBeads;

                lastHoleNumber = nextHoleNumber;
                nextHoleNumber++;
                if (nextHoleNumber >= this.gameMode.columnsPerSide * 2)
                    nextHoleNumber = 0;

            }
            selectedHole.numBeads = 0;

            // This is a pause move
            var move = new Move();
            move.playerID = -1;
            this.queue.push(move);

            selectedHoleNumber = lastHoleNumber;
            selectedHole = player.holes[lastHoleNumber];
           
            console.log("selectedHoleNumber p", selectedHoleNumber);

            // Check opposite Hole


            var oppositeHoleNumber = -1;
            console.log("columnsPerSide", (this.gameMode.columnsPerSide - 1));
            if (selectedHoleNumber > (this.gameMode.columnsPerSide - 1) && lastHoleBeadCount > 1) {
                oppositeHoleNumber = (this.gameMode.columnsPerSide * 2) - (selectedHoleNumber - this.gameMode.columnsPerSide) - 1;

                console.log("oppositeHoleNumber p", oppositeHoleNumber);

                var oppositeHole = player.opponent.holes[oppositeHoleNumber];

                if (oppositeHole.numBeads > 0) {

                    for (var i = 0; i < this.gameMode.startingBeads * 2; i++) {
                        if (this.allBeads[i].currentHole === oppositeHole) {

                            // Queue the move
                            var move = new Move();
                            move.playerID = player.id;
                            //move.beadID = i;
                            move.holeFrom = oppositeHole;
                            move.holeTo = player.holes[nextHoleNumber];
                            this.queue.push(move);

                            player.holes[nextHoleNumber].numBeads++;

                            lastHoleNumber = nextHoleNumber;
                            nextHoleNumber++;
                            if (nextHoleNumber >= this.gameMode.columnsPerSide * 2)
                                nextHoleNumber = 0;
                        }
                    }

                    oppositeHole.numBeads = 0;
                    selectedHoleNumber = lastHoleNumber;
                    selectedHole = this.holes[0][lastHoleNumber];
                }
            }

        }
        
    }
}


