import Rect from './rect.js'
import Vec from './vec.js'

export default class Hole extends Rect
{
    constructor(bao, context, x = 0, y = 0, beadSize = 30)
    {
        super(40, 40);
        this.vel = new Vec;
        this.score = 0;
        this._bao = bao;
        this._context = context;
        this._beadSize = beadSize;

        //this._lastPos = new Vec;

        this.suggested = false;
    }

    initialize(x = 0, y = 0, width = 0, height = 0) {
        this.pos.x = x + 2;
        this.pos.y = y + 2;

        this.size.x = width - 4;
        this.size.y = height - 4;

        //this.beads = new Array();
    }

    update(dt)
    {
        var beadsPerRow = 3;
        var mybeadSize = this._beadSize * 0.8;
        // if (this.beads.length > 9) {
        //     mybeadSize = this._beadSize * .75;
        //     beadsPerRow = 4;
        //     this.beads.forEach(bead => bead.needPosition = true);
        // }

        for (var i = 0; i < this.beads.length; i++) {
            //debugger;
            var bead = this.beads[i];

            if (bead.needPosition) {

                if (this.hittest(bead.pos))
                {
                    bead.TargetX = this.pos.x;
                    bead.TargetY = this.pos.y;
                    bead.tag = i + 1;

                    if (i > 0) {

                        var angle = i * 60;
                        var radius = mybeadSize * 2;

                        if (i > 6) {
                            angle = i * 30;
                            radius = mybeadSize * 4;

                            if (i > 18) {
                                angle = i * 20;
                                radius = mybeadSize * 6;
                            }

                        } 

                        var rad = angle * Math.PI / 180;
                        bead.TargetX = this.pos.x + radius * Math.cos(rad);
                        bead.TargetY = this.pos.y - radius * Math.sin(rad);
                        
                    }

                    //bead.TargetX += ((Math.random() * 10) - 5);
                    //bead.TargetY += ((Math.random() * 10) - 5);
    
                    bead.needPosition = false;
                }
                else
                {
                    // move directly to us ...
                    // bead.TargetX = this.left + 2 + beadSize / 2 + ((beadSize + 2) * col);
                    // bead.TargetY = this.top + 2 + beadSize / 2 + ((beadSize + 2) * row);

                    bead.TargetX = this.pos.x;
                    bead.TargetY = this.pos.y;
                    bead.tag = "***";
                    // unless you are already on our side. then ...
                    
                    // find the box that the ball is in ...

                    // if (this.player == "Player1") {
                    //     this._game.holes0.forEach(hole => {
                    //         if (hole.hittest(bead.pos)) {
                    //             bead.TargetX = hole.next.pos.x;
                    //             bead.TargetY = hole.next.pos.y;
                    //         }
                    //     });
                    // }

                    //bead.color = "#963312";
                }


            }
            bead.update(dt);

            // if (bead.mustMove()) {
            //     canMove = true;
            // }

        }
    }


    roundRect (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this._context.beginPath();
        this._context.moveTo(x + radius, y);
        this._context.arcTo(x + width, y, x + width, y + height, radius);
        this._context.arcTo(x + width, y + height, x, y + height, radius);
        this._context.arcTo(x, y + height, x, y, radius);
        this._context.arcTo(x, y, x + width, y, radius);
        
        this._context.closePath();
        this._context.fill();
      }

    draw() {


        this._context.save();
        this._context.fillStyle = "#fff";
        //this._context.fillRect(this.left, this.top, this.size.x, this.size.y);
        this._context.globalAlpha = 0.2;
        this.roundRect(this.left, this.top, this.size.x, this.size.y, 20);
        this._context.restore();

        if (this.selected)
        {
            this._context.fillStyle = this._bao.gameMode.selectedColor;
            this._context.fillRect(this.left + 10, this.top + this.size.y - 3, this.size.x - 20, 3);            
        } else if (true && this.suggested)
        {
            this._context.fillStyle = this._bao.gameMode.hintColor;
            this._context.fillRect(this.left + 10, this.top + this.size.y - 3, this.size.x - 20, 3);            
        }


        // this._context.beginPath();
        // this._context.arc(this.pos.x, this.pos.y, 2, 0, Math.PI * 2, true);
        // this._context.closePath();

        // // // var orangeGradient = this._context.createLinearGradient(this.left - this.size.x, 0, this.left + 2, 0);
        // // // orangeGradient.addColorStop(0, '#fff');
        // // // orangeGradient.addColorStop(1, this.color);
        // // // this._context.fillStyle = orangeGradient;
        
        // this._context.fillStyle = "#000";
        // // //this._context.fillRect(this.left, this.top, this.size.x * 2, this.size.y * 2);
        // this._context.fill();


        // // Draw ID, etc
        // this._context.fillStyle = "#FFFF99";
        // this._context.font = "10px Segoe UI";
        // this._context.textBaseline = "middle";
        // this._context.textAlign = "center";
        // //this._context.moveTo(this.pos.x, this.pos.y);
        // this._context.fillText(this.player + ":" + this.id, this.pos.x, this.pos.y);
        // //this._context.fillText(this.numBeads, this.size.x, this.size.y);


    }
}